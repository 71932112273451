import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '../stores/auth'

const routes = [
    {
      path: '/',
      name: 'Início',
      component: () => import('../components/HomePage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/regulamento',
      name: 'RegulamentoPage',
      component: () => import('../components/RegulamentoPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/privacidade',
      name: 'PrivacidadePage',
      component: () => import('../components/PrivacidadePage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/faq',
      name: 'FAQPage',
      component: () => import('../components/FAQPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/participantes',
      name: 'ParticipantesPage',
      component: () => import('../components/ParticipantesPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: () => import('../components/LoginPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/esqueci-senha',
      name: 'ForgotPasswordPage',
      component: () => import('../components/ForgotPasswordPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/resetar-senha',
      name: 'ResetPasswordPage',
      component: () => import('../components/ResetPasswordPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/cadastro',
      name: 'RegisterPage',
      component: () => import('../components/RegisterPage.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/meus-dados',
      name: 'MyDataPage',
      component: () => import('../components/MyDataPage.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cadastro-de-nota',
      name: 'RegisterNote',
      component: () => import('../components/RegisterNote.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cadastro-de-nota/foto',
      name: 'RegisterNotePicture',
      component: () => import('../components/RegisterNotePicture.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cadastro-de-nota/enviar-foto',
      name: 'RegisterNoteSendPicture',
      component: () => import('../components/RegisterNoteSendPicture.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/privacidade',
      name: 'PrivacidadePage',
      component: () => import('../components/PrivacidadePage.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/cadastro-de-nota/dados-da-nota',
      name: 'RegisterNoteData',
      component: () => import('../components/RegisterNoteData.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/contestar-nota/:id',
      name: 'NoteContestation',
      component: () => import('../components/NoteContestation.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/pontos',
      name: 'PointsPage',
      component: () => import('../components/PointsPage.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/ver-foto-nota/:id',
      name: 'PhotoNote',
      component: () => import('../components/PhotoNote.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/site-map',
      name: 'SiteMap',
      component: () => import('../components/SiteMap.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('../components/NotFound.vue'),
      meta: { requiresAuth: false }
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        return window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth'
        });
      }
    }
    return { x: 0, y: 0 };
  }
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()

  try {
    await authStore.checkAuth()

    if (to.meta.requiresAuth && !authStore.isAuthenticated) {
      if (to.path === '/login') {
        next()
      }
      next('/login')
    } else {
      next()
    }
  } catch (error) {
    console.error('Erro no router guard:', error)
    next('/login') // Redireciona para login em caso de erro
  }
})

export default router